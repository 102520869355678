<template>
  <div class="menu_wrapper_container box-shadow" v-loading="loading">
    <div class="trading-client">
    
      
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
        <el-card class="user_add_wrapper">
          <div>
            <el-row :gutter="20">
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Personal Details:</h3>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client">
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.name"
                  label="First Name"
                  prop="name"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="formData.name" autocomplete="off"></el-input>
                  <input v-model="formData.id" autocomplete="off" hidden />
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.email"
                  label="Email"
                  prop="email"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.email"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.phone"
                  label="Phone #"
                  prop="phone"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.phone"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.username"
                  label="Username"
                  prop="username"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.username"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.admin_unique_id"
                  label="Unique Id"
                  prop="admin_unique_id"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.admin_unique_id"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.renewaldate"
                  label="Renewal date"
                  prop="renewaldate"
                  :label-width="formLabelWidth"
                >
                  <br>
                  <DatePicker
                    :selectedDate.sync="formData.renewaldate"
                    :dialogType="dialogType"
                    :min-date="today()"
                    @dateUpdated="handleDateUpdate"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.password"
                  label="Password"
                  prop="password"
                  :label-width="formLabelWidth"
                >
                 
                  <el-input
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.transaction_password"
                  label="Transaction Password"
                  prop="transaction_password"
                  :label-width="formLabelWidth"
                >
                  <!-- <el-input
                    v-model="formData.transaction_password"
                    autocomplete="off"
                  ></el-input> -->
                  <el-input
                    v-model="formData.transaction_password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.allowedbroker"
                  label="Allowed User"
                  prop="allowedbroker"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.allowedbroker"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.allowedclient"
                  label="Allowed Client"
                  prop="allowedclient"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.allowedclient"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.is_able_modify_trade"
                    label=""
                    prop="is_able_modify_trade"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="formData.is_able_modify_trade"
                      >Modify Trades</el-checkbox
                    >
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.allow_to_broker_to_client_create"
                    label=""
                    prop="allow_to_broker_to_client_create"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="formData.allow_to_broker_to_client_create"
                      >Allow to broker to client create</el-checkbox
                    >
                  </el-form-item>
                </el-col>

            </el-row>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                  <el-form-item
                    :error="formErrors.allow_create_client"
                    label=""
                    prop="allow_create_client"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="formData.allow_create_client"
                      >Allow create client</el-checkbox
                    >
                  </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_edit_client"
                  label=""
                  prop="allow_edit_client"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_edit_client"
                    >Allow edit client</el-checkbox
                  >
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_create_duplicate"
                  label=""
                  prop="allow_create_duplicate"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_create_duplicate"
                    >Allow create duplicate</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_to_notification_view"
                  label=""
                  prop="allow_to_notification_view"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_to_notification_view"
                    >Allow to notification view</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_to_trade_for_client"
                  label=""
                  prop="allow_to_trade_for_client"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_to_trade_for_client"
                    >Allow to trade for client</el-checkbox
                  >
                </el-form-item>
              </el-col>


              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_mcx_trade"
                  label=""
                  prop="allow_mcx_trade"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_mcx_trade"
                    >Allow to MCX trade </el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_nse_trade"
                  label=""
                  prop="allow_nse_trade"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_nse_trade"
                    >Allow to NSE trade </el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_opt_trade"
                  label=""
                  prop="allow_opt_trade"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_opt_trade"
                    >Allow to OPT trade </el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_crypto_trade"
                  label=""
                  prop="allow_crypto_trade"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_crypto_trade"
                    >Allow to CRYPTO trade </el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item
                  :error="formErrors.allow_forex_trade"
                  label=""
                  prop="allow_forex_trade"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.allow_forex_trade"
                    >Allow to FOREX trade </el-checkbox
                  >
                </el-form-item>
              </el-col>
            
            </el-row>
          </div>
        </el-card>
        <br>
        <el-card class="user_add_wrapper">
          <div>
            <el-row>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <!-- <h3>Config:</h3> -->
                <el-form-item
                  :error="formErrors.admin_exposure_config"
                  label=""
                  prop="admin_exposure_config"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="formData.admin_exposure_config"
                    > <h3>Exposure Config: </h3></el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-if="formData.admin_exposure_config">
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                    <h3>MCX Turnover Basis:</h3>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                      :error="formErrors.mcx_inraday_exposure_max_value"
                      label="Inraday exposure max value"
                      prop="mcx_inraday_exposure_max_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="formData.mcx_inraday_exposure_max_value"
                        placeholder="ex. 1000x"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                      :error="formErrors.mcx_holding_exposure_max_value"
                      label="Holding exposure max value"
                      prop="mcx_holding_exposure_max_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="formData.mcx_holding_exposure_max_value"
                        placeholder="ex. 1000x"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row :gutter="20" class="trading-client">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                    <h3>MCX lot Basis:</h3>
                  </el-col>
                  <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                    <el-form-item
                        :error="formErrors.mcx_inraday_exposure_lot_min_value"
                        label="Inraday exposure lot min value"
                        prop="mcx_inraday_exposure_lot_min_value"
                        :label-width="formLabelWidth"
                        >
                        <el-input
                          v-model="formData.mcx_inraday_exposure_lot_min_value"
                          placeholder="ex. 100rs"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                  </el-col>

                  <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                    <el-form-item
                        :error="formErrors.mcx_inraday_exposure_lot_max_value"
                        label="Inraday exposure lot max value"
                        prop="mcx_inraday_exposure_lot_max_value"
                        :label-width="formLabelWidth"
                        >
                        <el-input
                          v-model="formData.mcx_inraday_exposure_lot_max_value"
                          placeholder="ex. 100000rs"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                  </el-col>

                  
                  <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                    <el-form-item
                        :error="formErrors.mcx_holding_exposure_lot_min_value"
                        label="Holding exposure lot min value"
                        prop="mcx_holding_exposure_lot_min_value"
                        :label-width="formLabelWidth"
                        >
                        <el-input
                          v-model="formData.mcx_holding_exposure_lot_min_value"
                          placeholder="ex. 100rs"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                    <el-form-item
                        :error="formErrors.mcx_holding_exposure_lot_max_value"
                        label="Holding exposure lot max value"
                        prop="mcx_holding_exposure_lot_max_value"
                        :label-width="formLabelWidth"
                        >
                        <el-input
                          v-model="formData.mcx_holding_exposure_lot_max_value"
                          placeholder="ex. 100000rs"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                  </el-col>
                </el-row>
                
              <el-row :gutter="20" class="trading-client">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                    <h3>NFO Turnover Basis:</h3>
                </el-col>
                <!-- nse  -->
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item
                      :error="formErrors.nse_inraday_exposure_max_value"
                      label="NSE inraday exposure max value"
                      prop="nse_inraday_exposure_max_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="formData.nse_inraday_exposure_max_value"
                        placeholder="ex. 1000x"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item
                      :error="formErrors.nse_holding_exposure_max_value"
                      label="NSE Holding exposure max value"
                      prop="nse_holding_exposure_max_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="formData.nse_holding_exposure_max_value"
                        placeholder="ex. 1000x"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>

                <!-- opt -->
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item
                      :error="formErrors.opt_inraday_exposure_max_value"
                      label="OPT Holding exposure max value"
                      prop="opt_inraday_exposure_max_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="formData.opt_inraday_exposure_max_value"
                        placeholder="ex. 1000x"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item
                      :error="formErrors.opt_holding_exposure_max_value"
                      label="OPT Holding exposure min value"
                      prop="opt_holding_exposure_max_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="formData.opt_holding_exposure_max_value"
                        placeholder="ex. 1000x"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>
                
              
              </el-row>
          </div>
        </el-card>
        <br>
        <el-card class="user_add_wrapper">
          <div>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item 
                    :error="formErrors.profit_loss_share_to_master_value"
                    label="Config profit loss share value"
                    prop="profit_loss_share_to_master_value"
                    :label-width="formLabelWidth"
                    >
                    <el-input
                      v-model="formData.profit_loss_share_to_master_value"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                    :error="formErrors.brokerage_share_to_master_value"
                    label="Config brokerage share value"
                    prop="brokerage_share_to_master_value"
                    :label-width="formLabelWidth"
                    >
                    <el-input
                      v-model="formData.brokerage_share_to_master_value"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
              </el-col>
              <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <SingleFileUpload ref="refFileUpload" :form-data="formData" accepted-extensions=".jpg,.png,.jpeg" />
              </el-col> -->
             
              
            </el-row>
          </div>
          <!-- Script -->
            <br>
            <div style="text-align: left;">
            <h3>Block Script</h3> <el-button type="primary" @click="addScript">+</el-button>
            </div>
           
          <!-- </div> -->

          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="right">
              <el-button @click="handelCancel()">Cancel</el-button>
              &nbsp;
              <el-button type="info" @click="sendChildFormData()">Save</el-button>
            </el-col>
          </el-row>
        </el-card>

        </el-form>
      
   

    </div>
     <ScriptAddEditDialog
      :dialog-visible.sync="dialogVisibleScriptAddEdit"
      :dialog-type="dialogTypeScriptAddEdit"
      :form-data="formData"
      :listScriptsMcx="listScriptsDataSetMcx"
      :listScriptsNse="listScriptsDataSetNse"
      :listScriptsOpt="listScriptsDataSetOpt"
      :listScriptsCrypto="listScriptsDataSetCrypto"
      :listScriptsForex="listScriptsDataSetForex"
      :form-errors.sync="formErrors"
      @getChildFormData="handleDuplicateAccount($event)"
      @childClose="handleDuplicateAccountClose()"
    />
  </div>
</template>




<script>
import { requiredRule, decimalRule } from "@/rules/all-rules";
// import getRoleSlug from "@/store/app.js";
import { store, edit, update } from "@/api/super-admin/admin";
import { mapGetters } from "vuex";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import { fetchList as scriptList } from "@/api/market-watch";
import ScriptAddEditDialog from "@/views/super-admin/users/components/ScriptAddEditDialog.vue";
//import SingleFileUpload from "@/views/super-admin/users/components/SingleFileUpload.vue";



export default {
  name: "AddEditDialog",
  components: {
    DatePicker,
    ScriptAddEditDialog,
    //SingleFileUpload
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      formData: {
        mcx_script: [],
        nse_script: [],
        opt_script: []
      },
      listScripts: {},
      dialogVisibleScriptAddEdit: false,
      dialogTypeScriptAddEdit: "edit",
      listScriptsDataSetMcx: {},
      listScriptsDataSetNse: {},
      listScriptsDataSetOpt: {},
      listScriptsDataSetCrypto: {},
      listScriptsDataSetForex : {}
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    dialogTitle() {
      return this.dialogType === "edit"
        ? "Add Pending Order"
        : "Edit Pending Order";
    },
  },
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.fetchData(this.$route.params.id);
    this.formRules.name = requiredRule("name");
    this.formRules.username = requiredRule("username");
    //this.formRules.admin_unique_id = requiredRule("admin_unique_id");
    this.formRules.transaction_password = requiredRule("transaction_password");
    // this.formRules.transaction_password = requiredRule("transaction_password");
    this.formRules.brokerage_share_to_master_value = decimalRule("brokerage_share_to_master_value");
    this.formRules.profit_loss_share_to_master_value = decimalRule("profit_loss_share_to_master_value");
    this.formRules.mcx_inraday_exposure_max_value = decimalRule("mcx_inraday_exposure_max_value", ["required"]);
    this.formRules.mcx_holding_exposure_max_value = decimalRule("mcx_holding_exposure_max_value", ["required"]);
    this.formRules.mcx_inraday_exposure_lot_max_value = decimalRule("mcx_inraday_exposure_lot_max_value", ["required"]);
    this.formRules.mcx_inraday_exposure_lot_min_value = decimalRule("mcx_inraday_exposure_lot_min_value", ["required"]);
    this.formRules.mcx_holding_exposure_lot_max_value = decimalRule("mcx_holding_exposure_lot_max_value", ["required"]);
    this.formRules.mcx_holding_exposure_lot_min_value = decimalRule("mcx_holding_exposure_lot_min_value", ["required"]);

    this.formRules.nse_inraday_exposure_max_value = decimalRule("nse_inraday_exposure_max_value", ["required"]);
    this.formRules.nse_holding_exposure_max_value = decimalRule("nse_holding_exposure_max_value", ["required"]);

    this.formRules.opt_inraday_exposure_max_value = decimalRule("opt_inraday_exposure_max_value", ["required"]);
    this.formRules.opt_holding_exposure_max_value = decimalRule("opt_holding_exposure_max_value", ["required"]);
    this.getScriptList()
  },
  methods: {
     addScript() {
        this.dialogVisibleScriptAddEdit = true;
        this.dialogTypeScriptAddEdit = "edit";
        this.listScriptsDataSetMcx = this.listScriptsMcx;
        this.listScriptsDataSetNse = this.listScriptsNse;
        this.listScriptsDataSetOpt = this.listScriptsOpt;
        this.listScriptsDataSetCrypto = this.listScriptsCrypto;
        this.listScriptsDataSetForex = this.listScriptsForex;
    },
    handleDuplicateAccountClose() {
      this.dialogVisibleScriptAddEdit = false;
      this.delectval = null;
    },
    handelCancel() {
      return this.$router.push("/super-admin/users");
    },
    sendChildFormData() {
      this.loading = true;

      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // this.formData = Object.assign(
          //               this.formData,
          //               this.$refs.refFileUpload.postForm
          // );
          console.log(this.formData);
          let check = this.formData.id ? "existUser" : "newUser" 
          if (check == 'existUser') {
            update(this.formData)
              .then((response) => {
                if (response.data.success === true) {
                  this.flashSuccess(response.data.message);
                  this.loading = false;
                  this.$router.push(
                    "/super-admin/admins"
                  );
                } else {
                  // this.formErrors = this.validationErrors(
                  //   response.data.message
                  // );
                  this.flashError(response.data.message);
                }
                this.loading = false;
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            store(this.formData)
              .then((response) => {
                if (response.data.success === true) {
                  this.flashSuccess(response.data.message);
                  this.loading = false;
                  this.$router.push("/super-admin/admins");
                } else {
                  this.formErrors = this.validationErrors(
                    response.data.message
                  );
                }
                this.loading = false;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
        this.loading = false;
      });
    },
    ///////////////////
    //// EDIT
    ///////////////////
    fetchData(id) {
      if (id) {
        this.fetchEditData(id);
      }
    },
    // fetch by id
    fetchEditData(id) {
      // this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.config = response.data.data.config;
            this.equity = response.data.data.equity;
            this.mcx = response.data.data.mcx;
            this.opt = response.data.data.opt;
            this.crypto = response.data.data.crypto;
            // this.dialogTypeScriptAddEdit = "edit";
            // this.loading = false;
          } else {
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    handleDateUpdate(value) {
      this.formData.renewaldate = value;
    },
    getScriptList() {
      scriptList().then((response) => {
        this.listScriptsNse  = response.data.data.nse;
        this.listScriptsMcx  = response.data.data.mcx;
        this.listScriptsOpt  = response.data.data.opt;
        this.listScriptsCrypto  = response.data.data.crypto;
        this.listScriptsForex  = response.data.data.forex;
      });
    },
  },
};
</script>

